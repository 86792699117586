import Vue from "vue";
import VueRouter from "vue-router";
import Api from '@/core/Api';
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    redirect: '/home',
    component: () => import( "@/views/layout/Main.vue"),
    children:[
      {
        path:'/home',
        name: 'Accueil',
        component: () => import( "@/views/Home.vue"),
        meta:{
          logged:true
        }
      },   
      {
        path:'/whatsapp',
        name: 'WhatsApp',
        component: () => import( "@/views/WhatsApp.vue"),
        meta:{
          logged:true
        }
      },                  
      {
        path:'/param',        
        component: () => import( "@/views/param/Main.vue"),
        children:[
          {
            path:'liste',
            name: 'Parametrage',
            component: () => import( "@/views/param/Liste.vue"),
            meta:{
              logged:true
            }
          },
          {
            path:'model/:name',
            name: 'Model',
            component: () => import( "@/views/param/Model.vue"),
            meta:{
              logged:true
            }
          },
        ]
      },
      {
        path:'/utilisateur',
        name: 'Utilisateur',
        component: () => import( "@/views/utilisateur/Main.vue"),
        children:[
          {
            path:'nouveau',
            name:'Nouvelle utilisateur',
            component: () => import( "@/views/utilisateur/Nouveau.vue"),
            meta:{
              logged:true
            }
          },
          {
            path:'detail/:id',
            name:'Detail de l\'utilisateur',
            component: () => import( "@/views/utilisateur/Nouveau.vue"),
            meta:{
              logged:true
            }
          },
          {
            path:'liste',
            name:'Liste des utilisateurs',
            component: () => import( "@/views/utilisateur/Liste.vue"),
            meta:{
              logged:true
            }
          },
          {
            path:'rdp',
            name: 'RDP',
            component: () => import( "@/views/utilisateur/RDP.vue"),
            meta:{
              logged:true
            }
          },
          {
            path:'vpn',
            name: 'VPN',
            component: () => import( "@/views/utilisateur/VPN.vue"),
            meta:{
              logged:true
            }
          },
        ]
      },
      {
        path:'/prospect',
        name: 'Prospect',
        component: () => import( "@/views/prospect/Main.vue"),
        children:[
          {
            path:'nouveau',
            name:'Nouveau prospect',
            component: () => import( "@/views/prospect/Nouveau.vue"),
            meta:{
              logged:true
            }
          },
          {
            path:'detail/:id',
            name:'Detail du prospect',
            component: () => import( "@/views/prospect/Nouveau.vue"),
            meta:{
              logged:true
            }
          },
          {
            path:'liste',
            name:'Liste des prospect',
            component: () => import( "@/views/prospect/Liste.vue"),
            meta:{
              logged:true
            }
          },
          {
            path:'fake',
            name:'Liste des fake',
            component: () => import( "@/views/prospect/Fake.vue"),
            meta:{
              logged:true
            }
          },
          {
            path:'appel/:phone',
            name:'Appel entrant',
            component: () => import( "@/views/prospect/Appel.vue"),
            meta:{
              logged:true
            }
          },
          {
            path:'stats',
            name:'Liste des stats',
            component: () => import( "@/views/prospect/Stats.vue"),
            meta:{
              logged:true
            }
          },
        ]
      },
      {
        path:'/sms',
        name: 'SMS',
        component: () => import( "@/views/sms/Main.vue"),
        children:[
          {
            path:'liste',
            name:'Liste des sms',
            component: () => import( "@/views/sms/Liste.vue"),
            meta:{
              logged:true
            }
          }
        ]
      },
      {
        path:'/devis',
        name: 'Devis',
        component: () => import( "@/views/devis/Main.vue"),
        children:[
          {
            path:'nouveau/:id',
            name:'Nouveau devis',
            component: () => import( "@/views/devis/Nouveau.vue"),
            meta:{
              logged:true
            }
          },
          {
            path:'detail/:id',
            name:'Detail du devis',
            component: () => import( "@/views/devis/Nouveau.vue"),
            meta:{
              logged:true
            }
          },
          {
            path:'liste',
            name:'Liste des devis',
            component: () => import( "@/views/devis/Liste.vue"),
            meta:{
              logged:true
            }
          },
          {
            path:'contrat/:id',
            name:'Nouveau contrat',
            component: () => import( "@/views/devis/Contrat.vue"),
            meta:{
              logged:true
            }
          }
        ]
      },
      {
        path:'/produit',
        name: 'Produit',
        component: () => import( "@/views/produit/Main.vue"),
        children:[
          {
            path:'nouveau',
            name:'Nouveau produit',
            component: () => import( "@/views/produit/Nouveau.vue"),
            meta:{
              logged:true
            }
          },
          {
            path:'detail/:id',
            name:'Detail du produit',
            component: () => import( "@/views/produit/Nouveau.vue"),
            meta:{
              logged:true
            }
          },
          {
            path:'liste',
            name:'Liste des produits',
            component: () => import( "@/views/produit/Liste.vue"),
            meta:{
              logged:true
            }
          },
          {
            path:'manquant',
            name:'Liste des produits manquant',
            component: () => import( "@/views/produit/Manquant.vue"),
            meta:{
              logged:true
            }
          }
        ]
      },
      {
        path:'/mutuelle',
        name: 'Mutuelle',
        component: () => import( "@/views/mutuelle/Main.vue"),
        children:[          
          {
            path:'liste',
            name:'Liste des mutuelles',
            component: () => import( "@/views/mutuelle/Liste.vue"),
            meta:{
              logged:true
            }
          }
        ]
      },
      {
        path:'/agenda',
        name: 'Agenda',
        component: () => import( "@/views/agenda/Main.vue"),
        children:[
          {
            path:'mois',
            name:'Agenda par mois',
            component: () => import( "@/views/agenda/Mois.vue"),
            meta:{
              logged:true
            }
          },
          {
            path:'semaine',
            name:'Agenda par semaine',
            component: () => import( "@/views/agenda/Semaine.vue"),
            meta:{
              logged:true
            }
          }
        ]
      },  
      {
        path:'/source',
        name: 'Source',
        component: () => import( "@/views/source/Main.vue"),
        children:[
          {
            path:'liste',
            name:'SourceListe',
            component: () => import( "@/views/source/Liste.vue"),
            meta:{
              logged:true
            }
          },          
        ]
      },      
      {
        path:'/distribution',
        name: 'Distribution',        
        component: () => import( "@/views/Distribution.vue"),
        meta:{
          logged:true
        }
      },      
    ]
  },  
  {
    path:'/login',
    name: 'Login',
    component: () => import( "@/views/Login.vue"),
    meta:{
      logged:false
    }
  },
  {
    path: "/document",
    component: () => import( "@/views/document/Main.vue"),
    children:[
      {
        path:'pdf',
        name: 'DocumentPDF',
        component: () => import( "@/views/document/PDF.vue"),
        meta:{
          logged:false
        }
      },
      {
        path:'f/:uuid',
        name: 'DocumentCommande',
        component: () => import( "@/views/document/File.vue"),
        meta:{
          logged:false
        }
      },
      {
        path:'test',
        name: 'DocumentTest',
        component: () => import( "@/views/document/Test.vue"),
        meta:{
          logged:false
        }
      }      
    ]
  },
  {
    path:'/parrainage/:code',
    name:'Parrainage',
    component: () => import( "@/views/Parrainage.vue"),
    meta:{
      logged:false
    }
  },
  {
    path:'/campagne/:q',
    name:'Campagne',
    component: () => import( "@/views/Campagne.vue"),
    meta:{
      logged:false
    }
  },
  {
    path:'/rdv/:token',
    name:'Nouveau RDV',
    component: () => import( "@/views/RDV.vue"),
    meta:{
      logged:false
    }
  },
  {
    path: "/parcour",    
    component: () => import( "@/views/parcour/Main.vue"),
    children:[
      {
        path:'client/:api',
        name: 'Parcour Client',
        component: () => import( "@/views/parcour/Client.vue"),
        meta:{
          logged:false
        }
      },   
    ]
  },
  { 
    path: "*",
    redirect: "/agenda/mois"
  },
];
async function backVerify(){
  var option = {
    "headers":{
      "Content-Type":"application/json",
      "Authorization": Api.getToken()
    }
  };
  var tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  var url = Api.url.api + '/authorization/verify?timezone=' + tz;
  var response = await fetch(url,option);
  var res = await response.json();  
  return res;
}
async function getParams(){
  var option = {
    "headers":{
      "Content-Type":"application/json",
      "Authorization": Api.getToken()
    }
  };
  var url = Api.url.api + '/param/all'
  var response = await fetch(url,option);
  var res = await response.json();
  return res;
}
const router = new VueRouter({
  routes,
});
router.beforeEach( async (to, from, next) => {
  if(to.meta.logged === true){
    if(Api.params === false){
      await getParams().then(res => {
        if(res.status){
          if(res.data.Societe != undefined){
            res.data.Societe.forEach((item) => {
              item.titre = item.raison_sociale
            });
          }
          Api.params = res.data
        }
      });
    }
    if(Api.getToken() != false){
      await backVerify().then(res => {
        if(res.status === true){
          Api.saveToken(res.data.token);
          Api.saveUser(res.data);
          next();
        }else{
          next({path:'/login', replace:true})
        }
      }).catch( () => {
        next({path:'/login'})
      });
    }else{
      next({path:'/login'})
    }
  }else{
    next();
  }
})

export default router;
