import router from "@/router";
export default {
  url:{
    // api: 'http://127.0.0.1:8001',
    // app:'http://localhost:8080',
    api: 'https://apiv2.laf.jg.app',
    app:'https://assureur-francais.jg.app',
    socket:'https://socket.laf.jg.app',
    //socket:'http://localhost:3001'
  },
  lang: localStorage.lang === undefined ? 'fr' : localStorage.lang,  
  ste: {
    key:null,
    name:"Assureur Français",
    logo:"https://apiv2.laf.jg.app/files/assets/logo.png",
    site:"https://jg-connect.com",
    symbol:"€",
    colors:{
      menu:{
        secondary:"#ffffff",
        primary: "#80b0d6"
      }
    }
  },
  uselee:"e7153db8-9053-4722-a9ca-33fc01c733d7",
  meta:null,
  params:false,
  moment:false,
  ajax(route, params = null, callBack) {
    const url = route.search('http') === 0 ? route : this.url.api + route;
    let the = this;
    let option = {};
    option.headers = {
      "Content-Type":"application/json",
      Authorization: this.getToken()
    }
    if(params != null){
      option.method = "POST",
      option.body = JSON.stringify(params)
    }
    fetch(url,option).then( response => {
      if(response.status === 401){
        the.destroyToken();
        the.destroyUser();
      }
      response.json().then( data =>{
        if(data.status === false){
          this.$toastr.e(data.data);
        }
        the.res = data;
        if(callBack != null) {
          callBack(the.res);
        }
      })
    });
  },  
  timestampToDate(timestamp,datetime = true){
    var date = new Date(timestamp*1000);
    var res = date.toLocaleDateString("fr-Fr");
    if(datetime){
      res += ' '+date.toLocaleTimeString("fr-Fr");
    }
    return res;
  },
  changeLang(lang){
    localStorage.lang = lang;
    this.lang = lang;
    location.reload();
  },
  setColors(){
    var root = document.querySelector(':root');
    root.style.setProperty('--menu-primary', this.ste.colors.menu.primary);
    root.style.setProperty('--menu-secondary', this.ste.colors.menu.secondary);
  },
  getToken(){
    var t = window.localStorage.getItem("token");
    if(t === undefined || t === null){
      return false;
    }
    return t;
  },
  saveToken(token){
    window.localStorage.setItem("token", token);
  },
  saveUser(user){
    this.user = user;
  },
  saveSte(ste){
    this.ste = ste;
  },
  getUser(){
    return this.user;
  },
  destroyToken(){
    window.localStorage.removeItem("token");
    router.push({path:"/login"})
  },
  destroyUser(){
    this.user = null;
  },
  now(){
    var n = new Date().getTime();
    n = n/1000;
    return parseInt(n);
  },
  getParam(obj, id){
    var check = this.params[obj];
    if(check === undefined){
      return {};
    }
    var tmp = check.find(p => p.id === id);
    if(tmp === undefined){
      return {};
    }
    return tmp;
  },
  p(obj, id){
    var check = this.params[obj];
    if(check === undefined){
      return {};
    }
    var tmp = check.find(p => p.id === id);
    if(tmp === undefined){
      return {};
    }
    return tmp;
  },
  jourRestant(date){
    if(date == null){
      return 0;
    }
    var now = this.moment(new Date());
    var end = this.moment(date);
    var duration = this.moment.duration(end.diff(now));
    var days = duration.asDays();
    return parseInt(days)
  },
  price(price){
    if(price === null){
      return null;
    }
    return price.toLocaleString('fr-FR', {
      style: 'currency',
      currency: 'EUR'
    });
  },
  acl(acl_id){    
    return this.user.acl.includes(acl_id);
  },
  uuid() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  },  
  call(phone){
    const base = phone;
    if(phone === null || phone === ""){
      this.$bvToast.toast(this.$store.back.getText('telephone_vide'), {
        title: 'Oups !',
        variant: 'danger',
        solid: true
      });
      return false;
    }
    phone = phone.replaceAll(" ","");
    phone = phone.replaceAll(".",""); 
    phone = phone.replaceAll("+","");
    if(phone[0]+phone[1] === "33"){
      phone = phone.replace("33","");
    }else if(phone[0] === "0"){
      phone = phone.replace("0","");
    }else{ 
      phone = base;
    }
    if(phone != base){      
      phone = this.pbx_prefix.code+phone;
    }
    var tag = document.createElement("tcxspan");
    tag.setAttribute("tcxhref",phone);
    document.body.appendChild(tag);
    setTimeout(() => {
      tag.click();
      tag.remove();
    },200);
  },
  link(path){
    return this.url.api+path;
  },
  format(titre){
    titre = titre.toLowerCase();
    titre = titre.replaceAll("(","");
    titre = titre.replaceAll(")", "");
    titre = titre.replaceAll(".", "");
    titre = titre.replaceAll("é", "e");
    titre = titre.replaceAll("à", "a");
    titre = titre.replaceAll("’", " ");
    titre = titre.replaceAll("/", "_");
    titre = titre.replaceAll("-", "");
    titre = titre.replaceAll("+", "");
    titre = titre.replaceAll("%", "");
    titre = titre.replaceAll("è", "e");
    titre = titre.replaceAll(",", "");
    titre = titre.replaceAll("&", "");
    titre = titre.replaceAll(" ", "_");
    titre = titre.replaceAll(":", "_");
    titre = titre.replaceAll(" _", "_");
    titre = titre.replaceAll("__", "_");
    titre = titre.replaceAll("__", "_");
    if(titre[titre.length-1] === "_"){
      titre = titre.substring(0,titre.length-1);
    }        
    return titre;
  },
  customEvent(name, type, data = false){
    const event = new CustomEvent(name, {detail: {type, data}});    
    document.dispatchEvent(event);            
  }
}
